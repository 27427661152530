import React from 'react'
import { StyledTextHighlight } from './styles';

import HtmlParser from '@commons/html-parser';

export default function TextHighlight({ color, text, display }) {

    // The ACF WYSIWYG deletes "p" tags when all of the text is highlighted
    if(display === 'block') return (
        <p>
            <StyledTextHighlight
                $color={color}
            >
                <HtmlParser stripBlockTags={true}>
                    {text}
                </HtmlParser>
            </StyledTextHighlight>
        </p>
    )

    return (
        <StyledTextHighlight
            $color={color}
        >
            <HtmlParser stripBlockTags={true}>
                {text}
            </HtmlParser>
        </StyledTextHighlight>
    )
}
