import styled from 'styled-components';

export const StyledTextHighlight = styled.mark`
    color: inherit;
    background-image: linear-gradient(180deg, transparent 0, ${props => props.$color || '#AF1E87'} 0);
    background-position: 0 .6em;
    background-repeat: no-repeat;
    background-color: transparent;
    /* padding: 0 .125em .125em .125em;
    margin: 0 -.125em -.125em -.125em; */
    padding: 0 0 .125em 0;
    margin: 0 0 -.125em 0;
`